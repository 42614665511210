import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChatSortingField, FlowrseIntegrationSortDirection } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import moment from "moment";
import { DeleteButtons } from "../../../AIAgents/components/DeleteButons";
import { EditButtons } from "../../../AIAgents/components/EditButtons";
import { PreviewButtons } from "../../../AIAgents/components/PreviewButtons";
import { ShareButtons } from "../../../AIAgents/components/ShareButtons";
import { useMemo } from "react";
export const useAgentsListTableColumns = ({ isAdmin, handleEditClick }) => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "external-Id",
                header: t("External ID"),
                showOnlyForFilterField: true,
                filter: {
                    name: "externalId",
                    type: "string",
                },
            },
            {
                key: "code",
                header: t("Agent Code"),
                accessor: "code",
                filter: {
                    name: "code",
                    type: "string",
                },
            },
            {
                key: "name",
                header: t("Agent Name"),
                accessor: "name",
                isSortable: true,
                sortColumnEnum: ChatSortingField.Name,
                sortDirection: FlowrseIntegrationSortDirection.Desc,
                filter: {
                    name: "name",
                    type: "string",
                },
            },
            {
                key: "description",
                header: t("Description"),
                accessor: "description",
                filter: {
                    name: "description",
                    type: "string",
                },
            },
            {
                key: "last-update",
                header: t("Last Modified Date"),
                accessor: ({ updatedAt }) => moment(updatedAt).format("DD MMM YYYY"),
                isSortable: true,
                sortColumnEnum: ChatSortingField.Updated,
                sortDirection: FlowrseIntegrationSortDirection.Desc,
            },
            {
                key: "KB Documents",
                header: t("KB Documents"),
                accessor: ({ documents }) => ((documents === null || documents === void 0 ? void 0 : documents.length) ? documents.length : "N/A"),
            },
            {
                key: "actions",
                header: t("Actions"),
                accessor: row => (_jsxs(Box, Object.assign({ display: 'flex', flexDirection: 'row', gap: 0 }, { children: [isAdmin && _jsx(ShareButtons, { agentId: row.id, users: row.contributors }), _jsx(PreviewButtons, { title: (row === null || row === void 0 ? void 0 : row.name) || t("Preview"), agentId: row.externalId }), _jsx(EditButtons, { agentId: row.id, handleEditClick: handleEditClick }), isAdmin && _jsx(DeleteButtons, { agentId: row.id, agentName: row.code })] }))),
            },
        ];
    }, []);
};
