import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useParams } from "react-router-dom";
import { AgentUpsertForm } from "../../forms";
import { useChatFlowQuery, useChatFlowUpdateMutation } from "../../gql";
import { convertAgentFormValuesToBackEndValues } from "../../utils";
export const AgentUpdateContainer = ({ isAdmin, handleNavigateToList, }) => {
    const { t } = useTranslation("domains");
    const { agentId } = useParams();
    const { data, loading } = useChatFlowQuery({
        variables: { chatFlowId: agentId },
        skip: !agentId,
        fetchPolicy: "no-cache",
    });
    const agent = data === null || data === void 0 ? void 0 : data.chatFlow;
    const { succeeded, failed } = useAddToast();
    const [chatFlowUpdateMutation, { loading: isSubmitting }] = useChatFlowUpdateMutation({
        onCompleted: (data) => {
            var _a;
            if ((_a = data === null || data === void 0 ? void 0 : data.chatFlowUpdate) === null || _a === void 0 ? void 0 : _a.id) {
                succeeded(t("Agent updated successfully!"));
                handleNavigateToList();
            }
            else {
                failed(t("Agent update failed!"));
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
    });
    const onAgentUpsertFormChange = (values) => {
        const _values = convertAgentFormValuesToBackEndValues(values);
        chatFlowUpdateMutation({
            variables: {
                chatFlowUpdateId: agentId,
                input: _values,
            },
        });
    };
    return (_jsx(AgentUpsertForm, { handleCancel: handleNavigateToList, isLoading: loading, agent: agent, isSubmitting: isSubmitting, submitButtonLabel: t("Update"), onChange: onAgentUpsertFormChange, isAdmin: isAdmin }));
};
