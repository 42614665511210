import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast, useGridContext } from "@toolkit/ui";
import { useSyncChatFlowMutation } from "../gql";
export const SyncButton = () => {
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const { refetch } = useGridContext();
    const [syncChatFlow, { loading }] = useSyncChatFlowMutation({
        onCompleted: (data) => {
            if (data === null || data === void 0 ? void 0 : data.syncChatFlow) {
                succeeded(t("AI Agent's Synced successfully!"));
                refetch({ first: 10, filter: null, after: null });
            }
            else {
                failed(t("AI Agent's Sync failed!"));
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
    });
    const onClick = () => {
        syncChatFlow({});
    };
    return (_jsx(Button, Object.assign({ onClick: onClick, disabled: loading, sx: {
            paddingX: "40px",
            marginRight: "20px",
        } }, { children: t("Sync") })));
};
